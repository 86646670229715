<template>
  <v-text-field
    v-model="addrs"
    :error-messages="addressError"
    :label="$t(label)"
    @blur="$v.addrs.$touch()"
    id="address"
    outlined
    autocomplete="no-address"
    @change="changeHandler"
  ></v-text-field>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
export default {
  name: "GooglePlace",
  props: ["label", "validationMsg", "address", "isRequired"],
  data() {
    return {
      addrs: "",
      cpatuedAddressFields: {},
      googleAutocomplete: "",
    };
  },
  validations: {
    addrs: {
      required: requiredIf(function () {
        return this.isRequired;
      }),
    },
  },
  computed: {
    addressError() {
      const errors = [];
      if (!this.$v.addrs.$dirty) return errors;
      !this.$v.addrs.required && errors.push(this.$t(this.validationMsg));
      return errors;
    },
  },
  mounted() {
    this.addrs = this.address;
    const fieldsToCapture = {
      administrative_area_level_1: "long_name", //state
      postal_code: "short_name", //zip
    };

    const google = window.google;
    const autocomplete = document.querySelector("#address");
    this.googleAutocomplete = new google.maps.places.Autocomplete(
      autocomplete,
      {
        types: ["geocode"],
      }
    );

    this.googleAutocomplete.addListener("place_changed", () => {
      this.cpatuedAddressFields = {};
      const place = this.googleAutocomplete.getPlace();
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (fieldsToCapture[addressType]) {
          this.cpatuedAddressFields[fieldsToCapture[addressType]] =
            place.address_components[i][fieldsToCapture[addressType]];
        }
      }
      this.addrs = autocomplete.value;
      this.changeHandler();
    });
  },
  methods: {
    changeHandler() {
      this.$emit("onAddressSelect", {
        valid: !this.$v.addrs.$invalid,
        address: this.addrs,
        state: this.cpatuedAddressFields.long_name,
        zip: this.cpatuedAddressFields.short_name,
      });
    },
  },
};
</script>
